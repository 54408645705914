<template>
  <fieldset class="row mb-3">
    <legend class="col-form-label col-sm-2 pt-0 text-end">{{ label }}</legend>
    <div class="col-sm-10">
      <div
        v-for="(item, index) in items"
        :key="index"
        :class="['form-check', { 'is-invalid': v?.$error, 'form-check-inline': isInline }]"
      >
        <input
          :id="inputName + index"
          v-model="name"
          class="form-check-input"
          type="radio"
          :name="inputName"
          :value="item.id"
        />
        <label class="form-check-label" :for="inputName + index">
          {{ item.name }}
        </label>
      </div>
      <div v-if="v?.$error" class="invalid-feedback">
        {{ msg(v?.$errors[0]) }}
      </div>
    </div>
  </fieldset>
</template>

<script>
import message from '@/helper/useVuelidate.js'
export default {
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    isInline: {
      type: Boolean,
      default: false
    },
    inputName: {
      type: String,
      default: ''
    },
    v: {
      type: Object,
      default: null
    }
  },
  emits: ['update:modelValue'],
  computed: {
    name: {
      get() {
        return this.modelValue
      },
      set(modelValue) {
        this.$emit('update:modelValue', modelValue.toString())
      }
    }
  },
  methods: {
    msg(s) {
      return message(s)
    }
  }
}
</script>
