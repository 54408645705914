<template>
  <div :class="col">
    <label :class="labelcol" v-text="label"></label>
    <div :class="inputcol">
      <textarea
        v-model="name"
        :class="{ 'is-invalid': v?.$error }"
        class="form-control"
        :readonly="readonly"
        :disabled="readonly"
        :rows="rows"
      ></textarea>
      <div v-if="v?.$error" class="invalid-feedback">
        {{ msg(v?.$errors[0]) }}
      </div>
      <div v-if="helper" class="form-text" v-text="helper"></div>
    </div>
  </div>
</template>

<script>
import message from '@/helper/useVuelidate.js'
export default {
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    col: {
      type: String,
      default: 'col-md-12'
    },
    labelcol: {
      type: String,
      default: 'form-label'
    },
    inputcol: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    helper: {
      type: String,
      default: ''
    },
    rows: {
      type: Number,
      default: 5
    },
    readonly: {
      type: Boolean,
      default: false
    },
    v: {
      type: Object,
      required: false,
      default: Object.assign({})
    }
  },
  emits: ['update:modelValue'],
  computed: {
    name: {
      get() {
        return this.modelValue
      },
      set(modelValue) {
        this.$emit('update:modelValue', modelValue)
      }
    }
  },
  methods: {
    msg(s) {
      return message(s)
    }
  }
}
</script>
