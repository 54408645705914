<template>
  <div :class="col">
    <label :class="labelcol" v-text="label"></label>
    <div :class="inputcol">
      <input
        ref="file"
        class="form-control"
        type="file"
        :class="{ 'is-invalid': v?.$error }"
        :readonly="readonly"
        :disabled="readonly"
        :accept="accept"
        @change="handleFileUpload"
      />
      <div v-if="v?.$error" class="invalid-feedback">
        {{ msg(v?.$errors[0]) }}
      </div>
      <div v-if="helper" class="form-text" v-text="helper"></div>
    </div>
  </div>
</template>

<script>
import message from '@/helper/useVuelidate.js'
export default {
  props: {
    modelValue: {
      type: File,
      default: null
    },
    col: {
      type: String,
      default: 'col-md-12'
    },
    labelcol: {
      type: String,
      default: 'form-label'
    },
    inputcol: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    helper: {
      type: String,
      default: ''
    },
    image: {
      type: Object,
      default: Object.assign({})
    },
    link: {
      type: Object,
      default: Object.assign({})
    },
    readonly: {
      type: Boolean,
      default: false
    },
    accept: {
      type: String,
      default: 'image/*'
    },
    v: {
      type: Object,
      required: false,
      default: Object.assign({})
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      lightbox: {
        visible: false,
        index: 0
      }
    }
  },
  methods: {
    msg(s) {
      return message(s)
    },
    handleFileUpload() {
      this.$emit('update:modelValue', this.$refs.file.files[0])
    },
    showImg(index) {
      this.lightbox.index = index
      this.lightbox.visible = true
    },
    handleHide() {
      this.lightbox.visible = false
    }
  }
}
</script>
