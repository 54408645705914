<template>
  <div class="content">
    <h1 class="page-title">
      {{ title }}<small class="text-muted">{{ titleEng }}</small>
    </h1>
    <input-basic
      v-model="forms.subject"
      :v="v$.forms.subject"
      label="*題名"
      col="row mb-3 mt-3"
      labelcol="col-form-label col-sm-2 text-end"
      inputcol="col-sm-10"
      :maxlength="v$.forms.subject.maxLength.$params.max"
    />
    <input-basic
      v-model="forms.subjectEng"
      :v="v$.forms.subjectEng"
      label="*題名(英)"
      col="row mb-3"
      labelcol="col-form-label col-sm-2 text-end"
      inputcol="col-sm-10"
      :maxlength="v$.forms.subjectEng.maxLength.$params.max"
    />
    <input-basic
      v-model="forms.author"
      :v="v$.forms.author"
      label="*通訊作者"
      col="row mb-3"
      labelcol="col-form-label col-sm-2 text-end"
      inputcol="col-sm-10"
      :maxlength="v$.forms.author.maxLength.$params.max"
    />
    <input-basic
      v-model="forms.authors"
      :v="v$.forms.authors"
      label="*其他作者"
      col="row mb-3"
      labelcol="col-form-label col-sm-2 text-end"
      inputcol="col-sm-10"
      :maxlength="v$.forms.authors.maxLength.$params.max"
    />
    <input-basic
      v-model="forms.mechanism"
      :v="v$.forms.mechanism"
      label="*任職機構"
      col="row mb-3"
      labelcol="col-form-label col-sm-2 text-end"
      inputcol="col-sm-10"
      :maxlength="v$.forms.mechanism.maxLength.$params.max"
    />
    <input-basic
      v-model="forms.email"
      :v="v$.forms.email"
      label="*EMAIL"
      col="row mb-3"
      labelcol="col-form-label col-sm-2 text-end"
      inputcol="col-sm-10"
      :maxlength="v$.forms.email.maxLength.$params.max"
    />
    <input-basic
      v-model="forms.keyword"
      :v="v$.forms.keyword"
      label="*關鍵詞"
      col="row mb-3"
      labelcol="col-form-label col-sm-2 text-end"
      inputcol="col-sm-10"
      :maxlength="v$.forms.keyword.maxLength.$params.max"
    />
    <input-basic
      v-model="forms.keywordEng"
      :v="v$.forms.keywordEng"
      label="*關鍵詞(英)"
      col="row mb-3"
      labelcol="col-form-label col-sm-2 text-end"
      inputcol="col-sm-10"
      :maxlength="v$.forms.keywordEng.maxLength.$params.max"
    />
    <text-basic
      v-model="forms.summary"
      :v="v$.forms.summary"
      label="*摘要"
      :rows="3"
      col="row mb-3"
      labelcol="col-form-label col-sm-2 text-end"
      inputcol="col-sm-10"
      :maxlength="v$.forms.summary.maxLength.$params.max"
    />
    <text-basic
      v-model="forms.summaryEng"
      :v="v$.forms.summaryEng"
      label="*摘要(英)"
      :rows="3"
      col="row mb-3"
      labelcol="col-form-label col-sm-2 text-end"
      inputcol="col-sm-10"
      :maxlength="v$.forms.summaryEng.maxLength.$params.max"
    />
    <radio-basic
      v-model="forms.kind"
      :v="v$.forms.kind"
      input-name="kind"
      :items="options.kind"
      label="*論文性質"
    />
    <file-basic
      v-model="files.pickFile"
      :v="v$.files.pickFile"
      label="*檔案"
      col="row mb-3"
      labelcol="col-form-label col-sm-2 text-end"
      inputcol="col-sm-10"
      accept=".doc,.docx"
      helper="檔案類型限制為 .doc,.docx，檔案大小不得超過 5 MB"
    />
    <p class="text-center mt-3">
      <button
        type="button"
        class="btn btn-primary"
        :disabled="submitStatus === 'PENDING'"
        @click="clickSubmit"
      >
        <font-awesome-icon icon="check" /> 確認投稿
      </button>
    </p>
  </div>
</template>

<script>
import { PublicAPI } from '@/api/index'
import AxiosErrMsg from '@/helper/AxiosErr.js'
import isValidScroll from '@/helper/useVuelidateScroll.js'
import FileSize from '@/helper/useVuelidateFile.js'

import InputBasic from '@/components/form/input.vue'
import FileBasic from '@/components/form/file.vue'
import TextBasic from '@/components/form/textarea.vue'
import RadioBasic from '@/components/form/radio.vue'

import useVuelidate from '@vuelidate/core'
import { required, email, maxLength, numeric } from '@vuelidate/validators'

export default {
  components: { InputBasic, FileBasic, TextBasic, RadioBasic },
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  data() {
    return {
      submitStatus: null,
      title: this.$route.meta.nav,
      titleEng: this.$route.meta.navEng,
      forms: {
        subject: '',
        subjectEng: '',
        author: '',
        authors: '',
        mechanism: '',
        email: '',
        keyword: '',
        keywordEng: '',
        kind: '',
        summary: '',
        summaryEng: '',
        pickFile: null
      },
      files: {
        pickFile: null
      },
      options: {
        kind: [
          { id: '1', name: '特邀論文' },
          { id: '2', name: '專題研究論文' },
          { id: '3', name: '一般研究論文' },
          { id: '4', name: '研究紀要' },
          { id: '5', name: '經典再現' },
          { id: '6', name: '口述歷史' },
          { id: '7', name: '田野筆記' }
        ]
      }
    }
  },
  mounted() {
    if (this.$store.state.auth.passport == '') {
      this.$swal({
        title: '請登入會員',
        icon: 'warning'
      })
      this.$router.push({ name: 'login' })
    }
  },
  methods: {
    async contentPost(formData) {
      let loader = this.$loading.show()
      await PublicAPI.Submits(formData)
        .then(() => {
          this.$swal({
            title: '',
            html:
              '<p class="text-start">' +
              this.forms.author +
              '您好</p>' +
              '<p class="text-start">我們已收到您投稿《傳播研究與實踐》之論文 &lt;' +
              this.forms.subject +
              '&gt; 。</p><p class="text-start">編委會將儘快展開評審作業，待評審結果出爐時，會再進一步與您聯繫。謝謝您的來稿！如有後續詢問，請在來信主旨欄載明，我們會盡速與您聯絡。</p>' +
              '<p class="text-start">順頌<br>時祺</p>' +
              '<p class="text-end">《傳播研究與實踐》編輯委員會 敬上</p>',
            icon: 'success'
          })
          this.$router.push({ name: 'Home' })
        })
        .catch(err => {
          AxiosErrMsg(err, this.$swal)
        })
      loader.hide()
      this.submitStatus = null
    },
    clickSubmit() {
      this.v$.$touch()
      if (this.v$.$error) {
        isValidScroll(this)
        return
      }
      var formData = this.createFormData()
      this.submitStatus = 'PENDING'
      this.contentPost(formData)
    },
    createFormData() {
      var formData = new FormData()
      Object.keys(this.forms).forEach(key => {
        if (key == 'pickFile') return
        formData.append(key, this.forms[key])
      })

      if (this.files.pickFile != null)
        formData.append('pickFile', this.files.pickFile, this.files.pickFile?.name)

      return formData
    }
  },
  validations() {
    return {
      forms: {
        subject: { required, maxLength: maxLength(100) },
        subjectEng: { required, maxLength: maxLength(300) },
        author: { required, maxLength: maxLength(100) },
        authors: { required, maxLength: maxLength(300) },
        mechanism: { required, maxLength: maxLength(100) },
        email: { required, email, maxLength: maxLength(50) },
        keyword: { required, maxLength: maxLength(100) },
        keywordEng: { required, maxLength: maxLength(300) },
        kind: { required, numeric },
        summary: { required, maxLength: maxLength(500) },
        summaryEng: { required, maxLength: maxLength(5000) }
      },
      files: {
        pickFile: {
          required: function() {
            return this.files.pickFile != null
          },
          checksize: FileSize(5)
        }
      }
    }
  }
}
</script>

<style></style>
